<template>
  <b-modal
    id="modalbasic"
    size="lg"
    ref="modalbasic"
    @hide="close()"
    v-model="isVisible"
    :no-close-on-backdrop="true"
    :title="this.title"
    @ok='close()'
    @cancel='close()'>
    <messages />
    <b-row>
      <b-col>
        <b-card>
          <b-form>
            <b-row>
              <h6><b>Payment Date:</b> {{this.formatDate(paymentDetail.paymentDate)}}</h6>
            </b-row>
            <b-row>
              <h6><b>Company Name:</b> {{paymentDetail.legalCompanyName}}</h6>
            </b-row>
            <b-row>
              <h6><b>Application ID:</b> {{paymentDetail.amsAppID}}</h6>
            </b-row>
            <b-row>
              <h6><b>Purchased By:</b> {{paymentDetail.purchasedBy}}</h6>
            </b-row>
            <b-row>
              <h6><b>Transaction Type:</b> {{paymentDetail.paymentType}}</h6>
            </b-row>
            <b-row>
              <h6><b>Discount:</b> {{this.formatTotal(paymentDetail.discount)}}</h6>
            </b-row>
            <b-row>
              <h6><b>Total:</b> {{this.formatTotal(paymentDetail.total)}}</h6>
            </b-row>
          </b-form>
        </b-card>
        <br>
        <h3>Order Items:</h3>
        <b-card>
          <vuetable
              ref="vuetable"
              track-by="paymentDetailID"
              :api-mode="false"
              :data="paymentItems"
              :fields="paymentItemsFields"
              :css="css.table"
            />
        </b-card>
        <br>
        <h3>ICheckGateway Transactions:</h3>
        <b-card>
          <vuetable
              ref="vuetable"
              track-by="transactionStatusID"
              :api-mode="false"
              :data="paymentStatus"
              :fields="paymentStatusFields"
              :css="css.table"
            />
        </b-card>
      </b-col>
    </b-row>
  </b-modal>
</template>

<style lang="scss" scoped>
.is-invalid {
  border: 1px solid #dc3545 !important;
}

.multiselect-is-invalid ::v-deep .multiselect__tags {
  border: 1px solid #dc3545 !important;
}
</style>

<script>

import PaymentMixin from '../../mixins/PaymentMixin.vue'
import { mapMutations } from 'vuex'
import ModalMessages from '../../containers/navs/ModalMessages.vue'
import Vuetable from 'vuetable-2/src/components/Vuetable'
import CssTableConfig from '../../constants/VuetableBootstrapconfig'
import moment from 'moment/moment.js'

export default {
  name: 'paymentDetailModal',
  components: {
    messages: ModalMessages,
    Vuetable
  },

  mixins: [PaymentMixin],
  data: () => ({
    paymentItemsFields: undefined,
    css: undefined,
    _idfield: 'paymentDetailID',
    title: undefined,
    isVisible: false,
    paymentID: 0,
    perPage: 50
  }),

  async created () {
    // await this.getContactTypeOptions()
    // this.mapConsultants()
    this.css = CssTableConfig
    this.paymentItemsFields = [
      {
        name: 'title',
        sortField: 'title',
        title: 'Item',
        titleClass: '',
        dataClass: 'text-muted',
        width: '9%'
      },
      {
        name: 'unitPrice',
        sortField: 'unitPrice',
        title: 'Unit Price',
        titleClass: '',
        dataClass: 'text-muted',
        width: '9%',
        callback: this.formatTotal
      },
      {
        name: 'units',
        sortField: 'units',
        title: 'Units',
        titleClass: '',
        dataClass: 'text-muted',
        width: '9%'
      },
      {
        name: 'amount',
        sortField: 'amount',
        title: 'Amount',
        titleClass: '',
        dataClass: 'text-muted',
        width: '9%',
        callback: this.formatTotal
      },
      {
        name: 'discount',
        sortField: 'discount',
        title: 'Discount',
        titleClass: '',
        dataClass: 'text-muted',
        width: '9%'
      },
      {
        name: 'total',
        sortField: 'total',
        title: 'Total',
        titleClass: '',
        dataClass: 'text-muted',
        width: '9%',
        callback: this.formatTotal
      },
      {
        name: 'amsAppID',
        sortField: 'amsAppID',
        title: 'AMS App',
        titleClass: '',
        dataClass: 'text-muted',
        width: '9%'
      }
    ]
    this.paymentStatusFields = [
      {
        name: 'transactionID',
        sortField: 'transactionID',
        title: 'Transaction ID',
        titleClass: '',
        dataClass: 'text-muted',
        width: '9%'
      },
      {
        name: 'transactionStatusDate',
        sortField: 'transactionStatusDate',
        title: 'Status Date',
        titleClass: '',
        dataClass: 'text-muted',
        width: '9%',
        callback: this.formatDate
      },
      {
        name: 'confirmationID',
        sortField: 'confirmationID',
        title: 'Confirmation ID',
        titleClass: '',
        dataClass: 'text-muted',
        width: '9%'
      },
      {
        name: 'transactionResultName',
        sortField: 'transactionResultName',
        title: 'Result',
        titleClass: '',
        dataClass: 'text-muted',
        width: '9%'
      },
      {
        name: 'paymentType',
        sortField: 'paymentType',
        title: 'Payment Type',
        titleClass: '',
        dataClass: 'text-muted',
        width: '9%'
      },
      {
        name: 'amount',
        sortField: 'amount',
        title: 'Amount',
        titleClass: '',
        dataClass: 'text-muted',
        width: '9%',
        callback: this.formatTotal
      }
    ]
  },

  methods: {
    ...mapMutations([
      'setModalDangerMessage',
      'setModalInfoMessage'
    ]),
    async show (opts = {}) {
      this.title = opts.title

      if (opts.exitFunction) {
        this.exitFunction = opts.exitFunction
      }

      if (opts.transactionID) {
        this.transactionID = (opts.transactionID)
        await this.getPaymentDetailsByTransactionID(this.transactionID)
        await this.getPaymentItemsByTransactionID(this.transactionID)
        await this.getPaymentStatusByTransactionID(this.transactionID)
      }

      this.isVisible = true
    },

    close () {
      if (this.exitFunction) {
        this.exitFunction()
      }

      this.transactionID = 0
      this.setModalDangerMessage('')
      this.setModalInfoMessage('')
      this.isVisible = false
    },
    formatDate (value) {
      if (value === null) {
        return ''
      } else {
        return moment(value, 'YYYY-MM-DD').format('MMM Do, YYYY')
      }
    },
    formatTotal (value) {
      if (value === null) {
        return ''
      } else {
        return `$${value}`
      }
    }
  }
}
</script>
