<template>
  <div>
    <paymentDetailModal ref="paymentDetailModal"></paymentDetailModal>
    <confirmation ref="Confirmation"></confirmation>
    <datatable-heading
      :title="$t('menu.payments')"
      :changePageSize="changePageSize"
      :searchChange="_.debounce(searchChange, 500)"
      :from="from"
      :to="to"
      :total="total"
      :perPage="perPage"
      :tableFields="fields"
    >
    </datatable-heading>
    <b-overlay :show="gridLoading" variant="transparent" blur="5px" opacity="1">
      <b-row>
        <b-col sm="2">
          <label for="searchDate">Search Date</label>
          <b-form-input id="searchDate" type="date" @change="searchDateChange" v-model="searchDate"></b-form-input>
        </b-col>
      </b-row>
      <b-row>
        <b-colxx xxs="12">
          <b-card>
            <vuetable
              ref="vuetable"
              track-by="paymentID"
              :api-url="apiBase"
              :query-params="makeQueryParams"
              :per-page="perPage"
              :reactive-api-url="false"
              :fields="fields"
              :css="css.table"
              pagination-path
              :row-class="onRowClass"
              @vuetable:pagination-data="onPaginationData"
              @vuetable:load-error="handleLoadError"
              @vuetable:loading="onVuetableLoading"
              @vuetable:loaded="onVuetableLoaded"
            >
              <!-- <template slot="stateCode" slot-scope="props">
                <read-more-list :linesToShow="2" separator="," :itemsInLine="5" :listText="props.rowData.stateCode" />
              </template> -->
              <template slot="transactionID" slot-scope="props">
                <a href="javascript:void(0);" @click="openPaymentDetailModal(props.rowData.transactionID)">{{ props.rowData.transactionID }}</a>
              </template>
            </vuetable>
          </b-card>
          <vuetable-pagination-bootstrap
            class="mt-4"
            ref="pagination"
            @vuetable-pagination:change-page="onChangePage"
          />
        </b-colxx>
      </b-row>
    </b-overlay>
  </div>
</template>
<script>
import Confirmation from '../../../../components/Common/Confirmation.vue'
import Vuetable from 'vuetable-2/src/components/Vuetable'
import VuetablePaginationBootstrap from '../../../../components/Common/VuetablePaginationBootstrap'
import { BeaconapiUrl } from '../../../../constants/config'
import DatatableHeading from '../../../../containers/datatable/DatatableHeading'
import CssTableConfig from '../../../../constants/VuetableBootstrapconfig'
import moment from 'moment/moment.js'
import paymentDetailModal from '../../../../components/Modals/PaymentDetailModal.vue'
// import PaymentMixin from '../../../mixins/PaymentMixin.vue'
import { mapMutations } from 'vuex'

export default {
  props: {
    paymentID: {
      type: Number,
      default: 1
    }
  },
  components: {
    Vuetable,
    'vuetable-pagination-bootstrap': VuetablePaginationBootstrap,
    'datatable-heading': DatatableHeading,
    PaymentDetailModal: paymentDetailModal,
    Confirmation
  },
  // mixins: [PaymentMixin],
  data () {
    var _idfield = 'transactionID'
    return {
      isLoad: false,
      apiBase: `${BeaconapiUrl}/api/ecommerce/table`,
      sort: '',
      page: 1,
      perPage: 50,
      search: '',
      searchDate: '',
      from: 0,
      to: 0,
      total: 0,
      lastPage: 0,
      items: [],
      selectedItems: [],
      idfield: _idfield,
      css: CssTableConfig,
      gridLoading: false,
      fields: [
        {
          name: `__slot:${_idfield}`,
          sortField: _idfield,
          title: 'Payment ID',
          titleClass: '',
          dataClass: 'text-muted',
          width: '9%'
        },
        {
          name: 'amsCoID',
          sortField: 'amsCoID',
          title: 'Company ID',
          titleClass: '',
          dataClass: 'text-muted',
          width: '9%'
        },
        {
          name: 'companyName',
          sortField: 'companyName',
          title: 'Company Name',
          titleClass: '',
          dataClass: 'text-muted',
          width: '15%'
        },
        {
          name: 'transactionDate',
          sortField: 'transactionDate',
          title: 'Payment Date',
          titleClass: '',
          dataClass: 'text-muted',
          width: '8%',
          callback: this.formatDate
        },
        {
          name: 'purchasedBy',
          sortField: 'purchasedBy',
          title: 'Purchased By',
          titleClass: '',
          dataClass: 'text-muted',
          width: '10%'
        },
        {
          name: 'transactionType',
          sortField: 'transactionType',
          title: 'Transaction Type',
          titleClass: '',
          dataClass: 'text-muted',
          width: '10%'
        },
        {
          name: 'paymentType',
          sortField: 'paymentType',
          title: 'Payment Type',
          titleClass: '',
          dataClass: 'text-muted',
          width: '10%'
        },
        {
          name: 'total',
          sortField: 'total',
          title: 'Total',
          titleClass: '',
          dataClass: 'text-muted',
          width: '10%',
          callback: this.formatTotal
        }
      ],
      json_data: []
    }
  },
  mounted () {
    const urlParams = new URL(location.href).searchParams
    this.transactionID = urlParams.get('transactionID')
    if (this.transactionID) {
      console.log('sdfsdf', this.transactionID)
      this.$refs.PaymentDetailModal.show({
        title: `Payment ID: ${this.transactionID}`,
        transactionID: this.transactionID,
        exitFunction: () => {
          history.replaceState({}, '', location.pathname)
          this.refreshVueTable()
        }
      })
    }
  },
  methods: {
    ...mapMutations([
      'setDangerMessage',
      'setAlertMessage',
      'setInfoMessage',
      'appendInfoMessage'
    ]),
    makeQueryParams (sortOrder, currentPage, perPage) {
      this.selectedItems = []
      return sortOrder[0]
        ? {
          sort: sortOrder[0]
            ? sortOrder[0].field + '|' + sortOrder[0].direction
            : '',
          page: currentPage,
          per_page: this.perPage,
          search: this.search,
          searchDate: this.searchDate
        }
        : {
          page: currentPage,
          per_page: this.perPage,
          search: this.search,
          searchDate: this.searchDate
        }
    },
    onVuetableLoading () {
      this.gridLoading = true
    },
    onVuetableLoaded () {
      this.gridLoading = false
    },
    onPaginationData (paginationData) {
      this.from = paginationData.from
      this.to = paginationData.to
      this.total = paginationData.total
      this.lastPage = paginationData.last_page
      this.items = paginationData.data
      this.$refs.pagination.setPaginationData(paginationData)
      this.json_data = paginationData.data
    },
    onChangePage (page) {
      this.$refs.vuetable.changePage(page)
    },
    changePageSize (perPage) {
      this.perPage = perPage
      this.refreshVueTable()
    },
    searchChange (val) {
      this.search = val
      this.refreshVueTable()
    },
    searchDateChange (val) {
      console.log('here', val)
      this.searchDate = val
      this.refreshVueTable()
    },
    handleLoadError (response) {
      this.setDangerMessage(response)
    },
    onRowClass (dataItem, index) {
      var outputclass = ''
      if (this.selectedItems.includes(dataItem[this.$data.idfield])) {
        outputclass = 'selected'
      }
      return outputclass
    },
    formatDate (value) {
      if (value === null) {
        return ''
      } else {
        return moment(value, 'YYYY-MM-DD').format('MMM Do, YYYY')
      }
    },
    formatTotal (value) {
      if (value === null) {
        return ''
      } else {
        return `$${value}`
      }
    },
    openPaymentDetailModal (transactionID) {
      console.log(transactionID)
      this.$refs.paymentDetailModal.show({
        title: `Payment ID: ${transactionID}`,
        transactionID: transactionID,
        exitFunction: () => {
          history.replaceState({}, '', location.pathname)
          this.refreshVueTable()
        }
      })
      const urlParams = new URLSearchParams()
      urlParams.set('transactionID', transactionID)
      history.replaceState(null, null, '?' + urlParams.toString())
    },
    rightClicked (dataItem, field, event) {
      event.preventDefault()
      this.rightClickedItem = dataItem
      const itemId = dataItem[this.$data.idfield]
      if (!this.$refs.vuetable.selectedTo.includes(itemId)) {
        this.$refs.vuetable.selectedTo = [itemId]
      }
      this.$refs.contextmenu.show({ top: event.pageY, left: event.pageX })
      this.selectedItems = this.$refs.vuetable.selectedTo
    },
    refreshVueTable () {
      this.$refs.vuetable.refresh()
    }
  }
}
</script>
