<script>
import axios from 'axios'
import { BeaconapiUrl } from '../constants/config'
import { mapMutations } from 'vuex'
import { instance } from '../request/app-axios.js'

export default {
  data () {
    return {
      paymentDetail: {},
      paymentItems: [],
      paymentStatus: [],
      apiBase: BeaconapiUrl
    }
  },

  methods: {
    ...mapMutations([
      'setDangerMessage',
      'setAlertMessage',
      'setInfoMessage',
      'setModalDangerMessage',
      'setModalAlertMessage',
      'setModalInfoMessage',
      'appendInfoMessage',
      'appendDangerMessage'
    ]),
    async getPaymentItemsByTransactionID (transactionID) {
      await axios
        .get(`${BeaconapiUrl}/api/ecommerce/detail/${transactionID}`)
        .then((result) => {
          this.paymentItems = result.data
        })
        .catch((error) => {
          this.setModalDangerMessage(error)
        })
    },
    async getPaymentStatusByTransactionID (transactionID) {
      await axios
        .get(`${BeaconapiUrl}/api/ecommerce/status/${transactionID}`)
        .then((result) => {
          this.paymentStatus = result.data
        })
        .catch((error) => {
          this.setModalDangerMessage(error)
        })
    },
    async getPaymentDetailsByTransactionID (transactionID) {
      await axios
        .get(`${BeaconapiUrl}/api/ecommerce/${transactionID}`)
        .then((result) => {
          this.paymentDetail = result.data
        })
        .catch((error) => {
          this.setModalDangerMessage(error)
        })
    }
  }
}
</script>
